
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import styles from "pages/offline.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination, Controller } from "swiper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import Link from "next/link";
import Banner1Image from 'images/static/offline/banner_1.jpg';
import Banner2Image from 'images/static/offline/banner_2.jpg';
import Banner3Image from 'images/static/offline/banner_3.jpg';
import Banner4Image from 'images/static/offline/banner_4.jpg';
import Banner5Image from 'images/static/offline/banner_5.jpg';
import Banner6Image from 'images/static/offline/banner_6.jpg';
import Banner7Image from 'images/static/offline/banner_7.jpg';
import Banner8Image from 'images/static/offline/banner_8.jpg';
import Banner9Image from 'images/static/offline/banner_9.jpg';
import Banner10Image from 'images/static/offline/banner_10.jpg';
import TitleImageBlack from 'images/static/offline/HEMEKO_black.png';
import TaxFreeImage from 'images/static/offline/tax_free_image.png';
import BannerTextWhite from 'images/static/offline/HEMEKO lab_white.png';
import GoogleLogoSmall from 'images/icons/google_logo.svg';
import FullButton from "components/buttons/FullButton";
import { Axios } from "api";
import { captureException, captureMessage } from "@sentry/nextjs";
import { postScrapContextRef } from "context/PostScrapContext";
import { productScrapContextRef } from "context/ProductScrapContext";
import { CurationSection } from "components/sections/curation/CurationSection";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { GOOGLE_MAPS_API_KEY } from "common/const";
import useTranslation from "next-translate/useTranslation";
SwiperCore.use([Navigation, Pagination, Autoplay, Controller]);
const link = "https://www.google.com/maps/place/%ED%97%A4%EB%A9%94%EC%BD%94+%ED%99%8D%EB%8C%80%EC%A0%90/data=!4m6!3m5!1s0x357c99f555e47f9d:0xf4f0f7b7e422996b!8m2!3d37.5544355!4d126.922117!16s%2Fg%2F11w9ghd_9h?entry=ttu";
const BannerContent = ({ banner }) => {
    const { t } = useTranslation('common');
    return (<img style={{ width: '100%', height: `100vh`, objectFit: 'cover' }} src={banner.mobile_image_url} alt={banner.text || t('offline.bannerText')}/>);
};
const Offline = (props) => {
    const banners = [
        {
            mobile_image_url: Banner1Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner2Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner3Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner4Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner5Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner6Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner7Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner8Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner9Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        },
        {
            mobile_image_url: Banner10Image.src,
            title_text_image_url: "text",
            hide_overlay: false,
            link: ""
        }
    ];
    const PAGE_SIZE = 4;
    const cursorRef = useRef(null);
    const defaultCurationData = {
        curations: []
    };
    const [isLoading, setIsLoading] = useState(true);
    const [curationData, setCurationData] = useState(defaultCurationData);
    const [error, setError] = useState(false);
    const fetchCurations = async () => {
        setIsLoading(true);
        try {
            const res = await Axios.get('v1/curation/offline-rule/children', {
                params: {
                    cursor: cursorRef.current,
                    size: PAGE_SIZE
                }
            });
            if (res.status < 400) {
                cursorRef.current = res.data.cursor;
                setCurationData(oldVal => {
                    return {
                        curations: [...res.data.curations]
                    };
                });
            }
            else {
                captureMessage(JSON.stringify(res.data));
                setError(true);
            }
        }
        catch (e) {
            setError(true);
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    const fetchScraps = async (curations) => {
        try {
            const productIds = [], postIds = [];
            for (const curation of curations) {
                const info = extractIdInfoFromCuration(curation);
                productIds.push(...info.productIds);
                postIds.push(...info.postIds);
            }
            const data = {
                product_ids: productIds,
                post_ids: postIds
            };
            const res = await Axios.post('/v1/scraps/', data);
            if (res.status < 400) {
                const data = res.data;
                postScrapContextRef.current.addPostIds(data.scrapped_post_ids);
                productScrapContextRef.current.addProductIds(data.scrapped_product_ids);
            }
        }
        catch (e) {
            captureException(e);
        }
    };
    useLayoutEffect(() => {
        fetchScraps(curationData.curations);
    }, [curationData.curations]);
    const extractIdInfoFromCuration = (curation) => {
        const productIds = [];
        const postIds = [];
        if (curation.category === 'product') {
            const new_products = curation.item_list.map(item => item.id);
            productIds.push(...new_products);
        }
        else if (curation.category === 'post') {
            const new_posts = curation.item_list.map(item => item.id);
            postIds.push(...new_posts);
        }
        return {
            'productIds': productIds,
            'postIds': postIds
        };
    };
    useEffect(() => {
        fetchCurations();
    }, []);
    const { t } = useTranslation('common');
    return (<div className={styles.rootContainer}>
            <div className={styles.firstSectionContainer}>
                <div className={styles.bannerTitle}>
                    <img src={TitleImageBlack.src} alt={t('offline.title')}/>
                </div>
                <div className={styles.taxFree}>
                    <img src={TaxFreeImage.src} alt={t('offline.taxFree')}/>
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.textWrapper}>
                        <span className={styles.bannerSubText}>Trendy K-beauty Store</span>
                        <img style={{ width: '200px', height: '34px', objectFit: "cover" }} src={BannerTextWhite.src}/>
                        <span className={styles.bannerText}>Offline Store</span>
                        <div className={styles.viewMapButtonWrapper}>
                            <a href={link}>
                                <a>
                                    <FullButton height={36} borderRadius={60}>
                                        <span style={{ fontFamily: "Helvetica Neue", fontSize: '14px' }}>View Map</span>
                                    </FullButton>
                                </a>
                            </a>
                        </div>
                    </div>
                </div>
                <Swiper className={styles.swiper} loop={banners && (banners.length > 1)} autoplay={{
            delay: 4000,
            disableOnInteraction: false
        }} speed={800} spaceBetween={0} slidesPerView={1} threshold={20} pagination={{
            type: 'fraction',
            horizontalClass: styles.hiddenSwiper,
            currentClass: styles.hiddenSwiper
        }}>
                    {banners.map((banner, idx) => {
            return (<SwiperSlide key={idx}>
                                    <div className={styles.bannerItem}>
                                        <BannerContent banner={banner}/>
                                    </div>
                                </SwiperSlide>);
        })}
                </Swiper>

                <div className={styles.slideWrapper}>
                    {curationData.curations.map((curation, curationIdx) => {
            return (<CurationSection key={`curation-${curation.id}-${curationIdx}`} curation={curation} curationIdx={curationIdx} location="offlinepage"/>);
        })}
                </div>
                <div className={styles.googleMapTitleWrapper}>
                    <div>
                        <div className={styles.googleMapTitle}>Location</div>
                        <div className={styles.googleMapSubTitle}>25, Hongik-ro, Mapo-gu, Seoul, Republic of Korea</div>
                    </div>
                </div>
                <div className={styles.mapContainer}>
                    <Link href={link}>
                        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
                            <Map style={{ width: '100%', height: '300px' }} defaultCenter={{ lat: 37.554474, lng: 126.922094 }} defaultZoom={17} gestureHandling={'greedy'} disableDefaultUI={true}>
                                <Marker position={{ lat: 37.554474, lng: 126.922094 }}/>
                            </Map>
                        </APIProvider>
                    </Link>
                    <div className={styles.googleMapTextContainer}>
                        <div className={styles.googleMapButton}>
                            <GoogleLogoSmall />
                            <a href={link}>
                                <span className={styles.googleMapButtonText}>Open in Google Maps</span>
                            </a>
                            <div style={{ width: '26px', height: '26px' }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};
export default Offline;

    async function __Next_Translate__getStaticProps__195d0ea939b__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/offline',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d0ea939b__ as getStaticProps }
  